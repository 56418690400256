import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800">
      <h1 className="text-6xl font-bold">404</h1>
      <p className="text-xl mt-2">Page Not Found</p>
      <a
        href="/"
        className="mt-4 px-6 py-2 bg-[#284B5A] hover:bg-[#284B5A] text-white rounded-lg no-underline"
      >
        Go Home
      </a>
    </div>
  );
};

export default NotFound;
